import React from 'react'

import Icon from '../data/icon.png';
const Entete = () => {
    return (
        <div className="flex justify-between gap-3">
            <div className="flex flex-col gap-3">
                <h1>Complexe Scolaire privé Les Nobles</h1>
                <h1>Programme Mixte Français et National</h1>
                <h2>Tel: 26465666</h2>
            </div>
            <img className="mx-auto h-28 w-auto" src={Icon} alt="Your Company" />
            <div className="flex flex-col text-right gap-3">
                <h1> مـجــمـــع مدارس النـبـــلاء </h1>
                <h1>نظام فرنسي وطني معتمد</h1>
                <h2> 26465666: الهاتف</h2>
            </div>
        </div>
    )
}

export default Entete